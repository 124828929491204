// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-business-solutions-on-the-cloud-js": () => import("./../../../src/pages/business-solutions-on-the-cloud.js" /* webpackChunkName: "component---src-pages-business-solutions-on-the-cloud-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-current-jobs-js": () => import("./../../../src/pages/current-jobs.js" /* webpackChunkName: "component---src-pages-current-jobs-js" */),
  "component---src-pages-enable-the-cloud-js": () => import("./../../../src/pages/enable-the-cloud.js" /* webpackChunkName: "component---src-pages-enable-the-cloud-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-listing-js": () => import("./../../../src/pages/job-listing.js" /* webpackChunkName: "component---src-pages-job-listing-js" */),
  "component---src-pages-lowcode-nocode-js": () => import("./../../../src/pages/lowcode-nocode.js" /* webpackChunkName: "component---src-pages-lowcode-nocode-js" */),
  "component---src-pages-modernize-solutions-js": () => import("./../../../src/pages/modernize-solutions.js" /* webpackChunkName: "component---src-pages-modernize-solutions-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-public-sector-services-js": () => import("./../../../src/pages/public-sector-services.js" /* webpackChunkName: "component---src-pages-public-sector-services-js" */),
  "component---src-pages-talent-services-js": () => import("./../../../src/pages/talent-services.js" /* webpackChunkName: "component---src-pages-talent-services-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-transform-your-business-js": () => import("./../../../src/pages/transform-your-business.js" /* webpackChunkName: "component---src-pages-transform-your-business-js" */)
}

